import React, { useRef } from 'react';
import './Join.css';
import emailjs from '@emailjs/browser';

const Join = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_68n3oal', 'template_tm9twm1', form.current, 'JG7tCXAR7qUaybi9o')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className='join' id='join-us'>
            <div className='left-join'>
                <hr />
                <div>
                    <span className='stroke-text'>Ready To</span>
                    <span>Level Up</span>
                </div>
                <div>
                    <span>Your Body</span>
                    <span className='stroke-text'>With Us?</span>
                </div>
            </div>
            <div className='right-join'>
                <form ref={form} action='' className='email-container' onSubmit={sendEmail}>
                    <input type='email' name='user_email' placeholder='Enter your Email address' />
                    <button className='btn btn-join'>Join Now</button>
                </form>
            </div>
        </div>
    )
}

export default Join